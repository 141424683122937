<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-09-20 11:17:38
 * @LastEditors  : BigBigger
-->
<!--课程协议详情-->
<template>
	<div class="app-page-4">
		<div class="container">
			<div class="mineContainer">
				<div class="protocolContain">
					<!-- 个人信息 -->
					<div
						class="part"
						v-if="
							orderInfo &&
								orderInfo.courseInfo
									.whetherDisplayPersonalInfo === 1
						"
					>
						<div class="p-title">
							个人信息
							<van-button
								type="danger"
								size="mini"
								style="float: right;margin-top: 0.8em;"
								v-if="orderInfo.payInfo.confirmStatus == 1"
								@click="updateUserInfo"
								>修改个人信息</van-button
							>
						</div>

						<p class="p-item">
							姓名：{{ orderInfo.personalInfo.realName }}
						</p>

						<p class="p-item">
							性别：{{
								orderInfo.personalInfo.gender | sexFilter
							}}
						</p>

						<p class="p-item">
							电话：{{ orderInfo.personalInfo.phone }}
						</p>

						<template v-if="orderInfo.type === 1">
							<p class="p-item">
								新/老学员：{{
									orderInfo.personalInfo.newStudent
										| studentFilter
								}}
							</p>

							<p class="p-item">
								笔试总分：{{
									orderInfo.personalInfo.totalScore
								}}
							</p>

							<p class="p-item">
								笔试排名：{{ orderInfo.personalInfo.ranking }}
							</p>

							<p class="p-item">
								专业：{{ orderInfo.personalInfo.profession }}
							</p>

							<p class="p-item">
								学校：{{ orderInfo.personalInfo.school }}
							</p>

							<p class="p-item">
								地址：{{ orderInfo.personalInfo.address }}
							</p>
						</template>
						<p class="p-item">
							应届/往届：{{
								orderInfo.personalInfo.freshStudent
									| freshStudentFilter
							}}
						</p>
						<!-- 暂时取消备注区别   v-if="isZJ" -->
						<p class="p-item">
							备注：{{ orderInfo.personalInfo.remark }}
						</p>
						<!-- <p class="p-item" v-else>查分密码：{{orderInfo.personalInfo.remark}}</p> -->
					</div>
					<div class="part" v-if="orderInfo && orderInfo.courseInfo">
						<div class="p-title">课程信息</div>

						<p class="p-item">
							订单编号：{{ orderInfo.courseInfo.orderCode }}
						</p>

						<p class="p-item">
							下单时间：{{ orderInfo.courseInfo.createTime }}
						</p>

						<p class="p-item">
							课程名称：{{ orderInfo.courseInfo.courseGroupName }}
						</p>

						<div
							class="p-item class-info"
							v-if="
								orderInfo.courseInfo.whetheHaveClassManager ===
									1
							"
						>
							<span class="info">班级信息：</span>
							<p class="class-list">
								<span
									v-for="(item, index) in flightNameList"
									:key="index"
									>{{ item }}</span
								>
							</p>
						</div>

						<p
							class="p-item"
							v-if="
								orderInfo.courseInfo.whetheHaveClassManager !==
									1
							"
						>
							上课时间：{{ orderInfo.courseInfo.classTime }}
						</p>

						<p class="p-item">
							上课地点：{{ orderInfo.courseInfo.classLocation }}
						</p>

						<p class="p-item">
							支付类型：{{ orderInfo.courseInfo.payWay }}
						</p>

						<p class="p-item">
							课程费用：<span class="text-red"
								>￥{{
									orderInfo.courseInfo.throughPay === 1 &&
									orderInfo.throughPay === 1
										? orderInfo.courseInfo.showPrice
										: orderInfo.courseInfo.payMoney
								}}</span
							>
						</p>

						<p class="p-item">
							费用说明：{{ orderInfo.courseInfo.remark }}
						</p>
					</div>
					<!-- 职位信息 -->
					<div
						class="part"
						v-if="
							orderInfo &&
								orderInfo.positionInfo &&
								orderInfo.type === 1
						"
					>
						<div class="p-title">职位信息</div>

						<p class="p-item">
							职位名称：{{ orderInfo.positionInfo.positionName }}
						</p>

						<p class="p-item">
							部门名称：{{
								orderInfo.positionInfo.departmentName
							}}
						</p>

						<p class="p-item">
							单位名称：{{ orderInfo.positionInfo.employer }}
						</p>

						<p class="p-item">
							职位代码：{{ orderInfo.positionInfo.jobCode }}
						</p>

						<p class="p-item">
							招考人数：{{
								orderInfo.positionInfo.numberCandidates
							}}
						</p>
					</div>
					<!-- 优惠信息 -->
					<div
						class="part"
						v-if="
							orderInfo &&
								orderInfo.personalInfo.studentsPreferential ===
									1
						"
					>
						<div class="p-title">优惠信息</div>
						<p class="p-item">
							是否老学员：{{
								orderInfo.personalInfo.studentsPreferential ===
								1
									? '是(参与老学员优惠)'
									: '否'
							}}
						</p>
						<p class="p-item">
							老学员证明：<van-image
								:src="orderInfo.personalInfo.field01"
								width="1.5rem"
								height="1.5rem"
								fit="contain"
								@click="preview = true"
							></van-image>
							<van-image-preview
								v-model="preview"
								:images="[orderInfo.personalInfo.field01]"
							></van-image-preview>
						</p>
					</div>
					<!-- 支付信息 -->
					<div
						class="part"
						v-if="orderInfo && orderInfo.payInfo"
						style="margin-bottom:2.6rem;"
					>
						<div class="p-title">支付信息</div>

						<p class="p-item">
							下单时间：{{ orderInfo.payInfo.createTime }}
						</p>

						<p class="p-item">
							订单状态：<span
								:class="
									orderInfo.payInfo.orderStatus
										| orderClassFilter
								"
								>{{
									orderInfo.payInfo.orderStatus
										| orderStateFilter
								}}</span
							>
						</p>

						<template v-if="orderInfo.type === 1">
							<p class="p-item">
								审核时间：{{ orderInfo.payInfo.confirmTime }}
							</p>

							<p class="p-item">
								审核状态：<span
									:class="
										orderInfo.payInfo.confirmStatus
											| classFilter
									"
									>{{
										orderInfo.payInfo.confirmStatus
											| confirmStateFilter
									}}</span
								>
							</p>
						</template>

						<p class="p-item text-red">
							课程费用：<span class="text-red"
								>￥{{
									orderInfo.courseInfo.throughPay === 1 &&
									orderInfo.throughPay === 1
										? orderInfo.courseInfo.showPrice
										: orderInfo.courseInfo.payMoney
								}}</span
							>
						</p>

						<p
							v-if="orderInfo.courseInfo.throughPay === 1"
							class="p-item text-red"
						>
							缴费方式：{{
								orderInfo.throughPay | throughPayFilter
							}}
						</p>

						<p class="p-item">
							付款方式：{{ orderInfo.payWay | paywayFilter }}
						</p>

						<!-- <el-col  >
                <p class="p-item text-red">课程余款：{{orderInfo.payInfo.balance}}</p>
              </el-col> -->

						<p class="p-item">
							支付金额：<span class="text-red"
								>￥{{
									+orderInfo.payWay === 1
										? getPrice
										: orderInfo.courseInfo.deposit
								}}</span
							>
						</p>

						<p class="p-item">
							支付状态：<span class="text-red">{{
								orderInfo.payInfo.payStatus | payStateFilter
							}}</span>
						</p>
					</div>
					<!-- 立即支付 -->
					<div class="pay-now" v-if="showPay">
						<div class="p-right" v-if="orderInfo">
							<span>实付金额：</span>
							<!-- 定金支付 -->
							<span class="text-red"
								>￥{{
									+orderInfo.payWay === 1
										? getPrice
										: orderInfo.courseInfo.deposit
								}}</span
							>
							<button class="pay-btn" @click="toPay(id)">
								立即支付
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<updateUserInfo
			ref="updateUserInfo"
			@reload="getOrderDetail"
		></updateUserInfo>
	</div>
</template>
<script>
import { getOrderDetail, getFlightName } from '@/api/api';
import updateUserInfo from '@/components/mine/updateUserInfo';
import {
	SEX,
	STUDENT,
	FRESHSTUDENT,
	ORDERSTATE,
	CONFIRMSTATE,
	PAYSTATE,
	PAYWAY,
	THROUGH_PAY,
} from '@/config/config';
export default {
	name: 'course',
	components: {
		updateUserInfo,
	},
	middleware: 'accountVerification',
	data() {
		return {
			page: 1,
			limit: 1000,
			id: null,
			orderInfo: null,
			isZJ: process.env.VUE_APP_AREA === 'zj' ? true : false,
			flightIds: [],
			flightNameList: [],
			courseInfo: null,
			preview: false,
		};
	},
	mounted() {
		this.getOrderDetail();
	},
	filters: {
		sexFilter(val) {
			return SEX[+val] || '';
		},
		studentFilter(val) {
			return STUDENT[+val] || '';
		},
		freshStudentFilter(val) {
			return FRESHSTUDENT[+val] || '';
		},
		orderStateFilter(val) {
			return ORDERSTATE[+val] || '';
		},
		confirmStateFilter(val) {
			return CONFIRMSTATE[+val] || '';
		},
		payStateFilter(val) {
			return PAYSTATE[+val] || '';
		},
		paywayFilter(val) {
			return PAYWAY[+val];
		},
		throughPayFilter(val) {
			return THROUGH_PAY[+val] || '';
		},
		classFilter(val) {
			switch (val) {
				case 1: //待审核
					return 'waitreview';
				case 2: //已审核
					return 'hasreview';
				case 3: //等待
					return 'waitreview';
				case 4: //拒绝
					return 'nopass';
				case 5: //退班
					return 'returnClass';
			}
		},
		orderClassFilter(val) {
			switch (val) {
				case 1: //未支付
					return 'nopay';
				case 2: //已支付
					return 'ispay';
				case 3: //已取消
					return 'hasCancel';
			}
		},
	},
	computed: {
		showPay() {
			let state = false;
			if (this.orderInfo && this.orderInfo.payInfo) {
				// 如果是关联订单
				if (+this.orderInfo.type === 1) {
					if (
						+this.orderInfo.payInfo.orderStatus === 1 &&
						+this.orderInfo.payInfo.confirmStatus === 2
					) {
						state = true;
					} else {
						state = false;
					}
				} else {
					// 如果未支付
					if (+this.orderInfo.payInfo.orderStatus === 1) {
						state = true;
					} else {
						state = false;
					}
				}
			} else {
				state = false;
			}
			return state;
		},
		getPrice() {
			if (!this.orderInfo.courseInfo || !this.orderInfo.personalInfo)
				return '';
			if (
				this.orderInfo.personalInfo.studentsPreferential === 1 &&
				this.orderInfo.personalInfo.field01
			) {
				return this.orderInfo.personalInfo.afterOldStudentsPrice;
			}
			return this.orderInfo.courseInfo.payMoney;
		},
	},
	methods: {
		async getOrderDetail() {
			let id = this.$route.query.id;
			let orderInfo = null;
			let response = await getOrderDetail({ orderId: id });
			if (+response.returnCode === 10001) {
				orderInfo = response.data;
			}
			this.id = id;
			this.orderInfo = orderInfo;
			this.courseInfo = orderInfo.courseInfo;
			let str = orderInfo.courseInfo.flightNames;
			let strs = str.split(',');
			this.flightNameList = strs;
		},
		toPay(id) {
			this.$router.push({ path: `/course/signup/review?orderId=${id}` });
			// http://192.168.7.42:3050/bx-platfrom-web/course/signup/review?orderId=1329389066406764545
		},
		updateUserInfo() {
			this.$refs.updateUserInfo.show({
				orderId: this.$route.query.id,
				totalScore: this.orderInfo.personalInfo.totalScore,
				ranking: this.orderInfo.personalInfo.ranking,
				realName: this.orderInfo.personalInfo.realName,
				phone: this.orderInfo.personalInfo.phone,
			});
		},
	},
};
</script>
<style lang="less" scoped>
.mineContainer {
	width: 100%;
	overflow: hidden;
	// margin:0 auto 30px auto;
	// border: 1px solid #DBDBDB;
	display: flex;
}
.protocolContain {
	width: 100%;
	overflow: hidden;
	border-left: 1px solid #f2f2f5;
	float: left;
}
.part {
	padding: 0.2rem 4%;
	background: #fff;
	margin-bottom: 0.3rem;
	.p-title {
		height: 0.8rem;
		line-height: 0.8rem;
		font-weight: bold;
		// border-left: 3px solid #FF3F47;
		// padding: 10px 0px;
		font-size: 14px;
		border-bottom: 1px solid #f2f2f5;
	}
	.p-item {
		word-break: break-all;
		color: #333333;
		margin: 0.1rem 0;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 0.4rem;
	}
	.waitreview {
		/*待审核/等待*/
		color: #f7a121;
	}
	.hasreview {
		/*已审核*/
		color: #00b262;
	}
	.nopass {
		/*未通过*/
		color: #ff3f47;
	}
	.returnClass,
	.hasCancel {
		/*退班*/
		color: #999999;
	}
	.nopay {
		/*未支付*/
		color: #2e8df4;
	}
	.ispay {
		/*已支付*/
		color: #00b262;
	}
	.cancelOrder {
		/*取消订单*/
		color: #ff3f47;
	}
}
.text-red {
	color: #ff3f47;
}
.pay-now {
	position: fixed;
	bottom: 0;
	width: 90%;
	border-top: 1px solid #f2f2f5;
	padding: 0.35rem 5%;
	line-height: 0.8rem;
	background: #fff;
	.p-right {
		text-align: center;
		font-size: 16px;
		color: #333;
	}
	.pay-btn {
		width: 100%;
		height: 0.8rem;
		background: #ff3f47;
		border-radius: 6px;
		text-align: center;
		line-height: 0.8rem;
		font-size: 18px;
		color: #fff;
	}
}
// .classInfo{
//   span{
//     display: block;
//     width: 100%;
//   }
// }
.class-info {
	display: flex;
	.info {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.class-list span {
		display: block;
	}
}
</style>